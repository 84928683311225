import React from 'react'
import Data from '../data/Data'
import Icon from '@mui/material/Icon';
import { Biotech } from '@mui/icons-material';

const Footer = () => {

  console.log(Data.socialMediaLinks)

    return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 border-t border-t-gray-200">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            <Biotech style={{fontSize:'3rem'}}/>
            <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
              Swab Nanosolutions
            </span>
          </a>
          <div className="mt-6 lg:max-w-sm">
            <p className="text-sm text-gray-800">
            Swab Nanosolutions is working towards becoming the pioneers in Green Nanotechnology based products with a futuristic vision and mission to match up with the advancement of technology.
            </p>
          </div>
        </div>
        <div className="space-y-2 text-sm">
          <p className="text-base font-bold tracking-wide text-gray-900">
            Contacts
          </p>
          <div className="flex">
            <p className="mr-1 text-color-primary">Phone:</p>
            <div className="flex-col">
              <p className="mr-1 text-color-secondary hover:text-color-primary cursor-pointer">+91 8839331739</p>
              <p className="mr-1 text-color-secondary hover:text-color-primary cursor-pointer">+91 8383895012</p>
            </div>
          </div>
          <div className="flex">
            <p className="mr-1 text-gray-800">Email:</p>
            <p className="mr-1 text-color-secondary hover:text-color-primary cursor-pointer">
              swab.nanosolutions@gmail.com
            </p>
          </div>
          <div className="flex">
            <p className="mr-1 text-gray-800">Address:</p>
            <p className="mr-1 text-color-secondary hover:text-color-primary cursor-pointer">
              SWAB NANOSOLUTIONS PRIVATE LIMITED
              Khasra NO. 38/10, Vill: Mundka, Near Mundka Metro Yard
              New Delhi, Delhi
              110041, India
            </p>
          </div>
        </div>
        <div>
          <span className="text-base font-bold tracking-wide text-gray-900">
            Social
          </span>
          <div className="flex items-center mt-1 space-x-3">
            {
              Data.socialMediaLinks.map((item,index) => (
                <a
                  href={item.url}
                  target={"_blank"}
                  className="text-color-secondary transition-colors duration-300 hover:text-color-primary cursor-pointer"
                >
                  {item.iconName}
                </a>
              ))
            }       
          </div>
          <p className="mt-4 text-sm text-color-primary font-semibold uppercase">
            "Nature has already all the solutions, We just need to find the one."
          </p>
        </div>
      </div>
      {/* <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        <p className="text-sm text-gray-600">
          © Copyright 2020 Lorem Inc. All rights reserved.
        </p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <a
              href="/"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              F.A.Q
            </a>
          </li>
          <li>
            <a
              href="/"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="/"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Terms &amp; Conditions
            </a>
          </li>
        </ul>
      </div> */}
    </div>
    )
}

export default Footer
