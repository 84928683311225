import Icon from '@mui/material/Icon';
import React from 'react'
import Data from '../data/Data'

const Team = () => {
    return (
    <>
        {Data.coreMembers.map((item,index) => (
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="grid gap-10 lg:grid-cols-2 column-span-2 order-2">
                    <div className={`lg:pr-10 order-${index % 2 == 0 ? '1' : '2'}`}>
                        <h5 className="mb-4 text-4xl font-extrabold leading-none">
                            {item.title}
                        </h5>
                        <p className="mb-6 text-gray-900">
                            {item.description}
                        </p>
                        <p className="mb-4 text-sm font-bold tracking-widest uppercase">
                            Specialities
                        </p>
                        <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
                            {item.specialities.map((point,index) => (
                                <ul className="space-y-3">
                                    <li className="flex">
                                        <span className="mr-1">
                                            <Icon style={{fontSize:10}}>circle</Icon>
                                        </span>
                                        {point}
                                    </li>
                                </ul>
                            ))}
                        </div>  
                    </div>
                    <div className={`order-${index % 2 == 0 ? '2' : '1'}`}>
                        <img
                            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                            src={item.imageUri}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        ))}

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
                <p className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    Welcome our talented team of professionals
                </p>
            </div>

            <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-3 lg:max-w-screen-lg">
                {
                    Data.team.map((item,index) => (
                    <div>
                        <div className="relative pb-56 mb-4 rounded shadow lg:pb-64">
                            <img
                                className="absolute object-cover w-full h-full rounded"
                                src={item.imageUri}
                                alt="Person"
                            />
                        </div>
                        <div className="flex flex-col sm:text-center">
                            <p className="text-lg font-bold">
                                {item.title}
                            </p>
                            <p className="mb-1 text-xs text-gray-800">
                                {item.subtitle}
                            </p>
                            <div className="flex items-center space-x-3 sm:justify-center">
                                {
                                    item.socialMediaLinks.map((item,index) => (
                                        item.url && 
                                        <a
                                            href={item.url}
                                            target={"_blank"}
                                            className="text-color-secondary transition-colors duration-300 hover:text-color-primary cursor-pointer"
                                        >
                                        {item.iconName}
                                        </a>
                                    ))
                                }       
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>

    )
}

export default Team
